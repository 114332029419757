import React from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';
import ScrollAnimation from 'react-animate-on-scroll';
import './NavBarMobile.scss';

function NavBarMobile({ isActive, location, toggleMenu, menu }) {
    if (!isActive) {
        return null;
    }
    return (
        <div className="cfp-nav-mobile">
            <div className="page">
                <div className="container">
                    {menu.map((item, i) => {
                        const { titulo, link } = item;
                        const tit = `/${item.titulo.toLowerCase()}/`;
                        const delay = 150 * i;
                        return (
                            <AniLink
                                paintDrip
                                hex="#9ac24d"
                                duration={0.5}
                                to={link}
                                className={`${location.pathname.indexOf(tit) >= 0 ? 'active' : ''}`}
                                activeClassName="active"
                                onClick={toggleMenu}
                                key={i}
                            >
                                <ScrollAnimation
                                    offset={50}
                                    animateIn="fadeInRight"
                                    animateOnce={true}
                                    delay={delay}
                                    scrollableParentSelector=".cfp-nav-mobile"
                                >
                                    {item.titulo}
                                </ScrollAnimation>
                            </AniLink>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

NavBarMobile.propTypes = {
    isActive: PropTypes.bool.isRequired,
    toggleMenu: PropTypes.func.isRequired,
};

export default NavBarMobile;
