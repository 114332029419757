import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { StaticQuery, graphql } from 'gatsby';
import { getSafe } from '../../utils/getSafe';

import './Header.scss';

function Header(props) {
    const getTitle = pathname => {
        if (pathname !== undefined) {
            if (pathname.indexOf('/blog') >= 0) return 'Blog';
            if (pathname.indexOf('/receta') >= 0) return 'Recetas';
            if (pathname.indexOf('/planes-eliana') >= 0) return 'Planes';
            return pathname.replace(/\//g, '');
        }
        return null;
    };
    return (
        <StaticQuery
            query={graphql`
                query {
                    contentfulInicioPagina {
                        subtituloHero
                        tituloHero {
                            tituloHero
                        }
                    }
                }
            `}
            render={data => {
                let { uri: path } = props.location;
                path = path === undefined ? props.location.pathname : path;
                const isHome = path === '/' || path === '/home' || path === '/home/';
                const home = getSafe(() => data.contentfulInicioPagina, {});
                const titulo = getSafe(() => home.tituloHero.tituloHero);
                const sub = getSafe(() => home.subtituloHero);
                return (
                    <>
                        <ScrollAnimation
                            animateIn="fadeIn"
                            animateOnce={true}
                            className={`cfp-header ${isHome === true ? 'full' : 'normal'}`}
                            delay={500}
                            offset={50}
                        >
                            {isHome ? (
                                <>
                                    <div className="header-content">
                                        <div className="slogan">
                                            {titulo}
                                            <br />
                                            <div className="text-rotation">
                                                <div className="text-content">
                                                    {sub &&
                                                        sub.map((s, i) => (
                                                            <h2 className="rotatingText-adjective" key={i}>
                                                                {s}
                                                            </h2>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="page-title">{getTitle(path)}</div>
                            )}
                        </ScrollAnimation>
                    </>
                );
            }}
        />
    );
}

export default Header;
