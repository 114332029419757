import React from 'react';
import PropTypes from 'prop-types';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import NavBar from '../Header/NavBar';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import SEO from '../SEO/SEO';
import 'animate.css/animate.min.css';
import '../../styles/index.scss';

const Layout = ({ children, title, location, ...props }) => {
    return (
        <>
            <ScrollToTop location={location}/>
            <SEO title={title} />
            <div className="whatsapp-icon">
                <a target="_blank" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?phone=50764896899`}>
                    <img src={require('../../assets/images/whatsapp-icon.png')} alt="WhatsApp" />
                </a>
            </div>
            <NavBar location={location} />
            <Header location={location} />
            {children}
            <Footer />
        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
