import React from 'react';

export const IG = ({ instagram, instagramLink }) => {
    return (
        <div className="contact-info-row text">
            {instagramLink && (
                <a href={instagramLink} target="_blank" rel="noopener noreferrer">
                    <span className="contact-icon">
                        <img src={require('../assets/images/icons/ig.png')} alt="IG" />
                    </span>
                    {instagram}
                </a>
            )}
        </div>
    );
};
