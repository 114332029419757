import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getSafe } from '../../utils/getSafe';
import ScrollAnimation from 'react-animate-on-scroll';
import Contact from './Contact';
import { Email } from '../../UI/Email';
import { Phone } from '../../UI/Phone';
import { IG } from '../../UI/IG';
import './Footer.scss';

const Footer = props => {
    const { contentfulFooterSeccion } = useStaticQuery(
        graphql`
            query {
                contentfulFooterSeccion {
                    instagram
                    instagramLink
                    telefono
                    ubicacion {
                        childMarkdownRemark {
                            html
                        }
                    }
                    horarios {
                        childMarkdownRemark {
                            html
                        }
                    }
                    descripcion {
                        childMarkdownRemark {
                            html
                        }
                    }
                    correo
                }
            }
        `
    );
    const descripcion = getSafe(() => contentfulFooterSeccion.descripcion.childMarkdownRemark.html);
    const correo = getSafe(() => contentfulFooterSeccion.correo);
    const ubicacion = getSafe(() => contentfulFooterSeccion.ubicacion.childMarkdownRemark.html);
    const instagram = getSafe(() => contentfulFooterSeccion.instagram);
    const instagramLink = getSafe(() => contentfulFooterSeccion.instagramLink);
    const telefono = getSafe(() => contentfulFooterSeccion.telefono);
    const horarios = getSafe(() => contentfulFooterSeccion.horarios.childMarkdownRemark.html);
    return (
        <footer className="cfp-footer text">
            <div className="footer">
                <div className="cfp-container child">
                    <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                        <div className="column contact-us">
                            <div className="row">
                                <span dangerouslySetInnerHTML={{ __html: descripcion }} />
                            </div>
                            <div className="row">
                                <Contact ubicacion={ubicacion} horarios={horarios} />
                            </div>
                            <div className="row">
                                <IG instagram={instagram} instagramLink={instagramLink} />
                                <Email correo={correo} />
                                <Phone telefono={telefono} />
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
            <div className="year">
                <div className="whatsapp-icon">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://api.whatsapp.com/send?phone=${telefono.replace(/[- )(]/g, '')}`}
                    >
                        <img src={require('../../assets/images/whatsapp-icon.png')} alt="WhatsApp" />
                    </a>
                </div>
                Center Fit Panamá &copy;{new Date().getFullYear()}
            </div>
        </footer>
    );
};

export default Footer;
