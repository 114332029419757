import React from 'react';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

export const Email = ({ correo }) => {
    return (
        <div className="contact-info-row text">
            {correo && (
                <a href={`mailto:${correo}`}>
                    <span className="contact-icon">
                        <MailOutlineIcon />{' '}
                    </span>
                    {correo}
                </a>
            )}
        </div>
    );
};
