import React from "react";
import RoomSharpIcon from "@material-ui/icons/RoomSharp";
import ScheduleIcon from "@material-ui/icons/Schedule";
import "./Contact.scss";

const Contact = ({ ubicacion, horarios }) => {
    return (
        <div className="contact-us">
            {ubicacion && (
                <div className="contact-row">
                    <span className="contact-icon">
                        {' '}
                        <RoomSharpIcon style={{ position: 'relative', top: '-1px', left: '0px' }} />{' '}
                    </span>{' '}
                    <span dangerouslySetInnerHTML={{ __html: ubicacion }} />
                </div>
            )}
            {horarios && (
                <div className="contact-row">
                    <span className="contact-icon">
                        <ScheduleIcon style={{ position: 'relative', top: '-1px', left: '0px' }} />
                    </span>
                    <span dangerouslySetInnerHTML={{ __html: horarios.replace(/(?:\r\n|\r|\n)/g, '<br>') }} />
                </div>
            )}
        </div>
    );
};

export default Contact;
