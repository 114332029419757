const menu = [
    {
        titulo: 'Planes',
        link: '/planes',
    },
    {
        titulo: 'Planes Lic. Eliana',
        link: '/planes-eliana',
    },
    {
        titulo: 'Agendar',
        link: '/agendar',
    },
    {
        titulo: 'Preguntas',
        link: '/preguntas',
    },
    {
        titulo: 'Contacto',
        link: '/contacto',
    },
    {
        titulo: 'Blog',
        link: '/blog',
    },
    {
        titulo: 'Recetas',
        link: '/recetas',
    },
];

export default menu;
