import React, { Component } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import BurgerMenu from './BurgerMenu';
import NavBarMobile from './NavBarMobile';
import menu from '../../contenido/menu';
import './NavBar.scss';

class NavBar extends Component {
    state = {
        navbarOpen: false,
    };
    toggleMenu = () => {
        this.setState(
            prevState => {
                return {
                    navbarOpen: !prevState.navbarOpen,
                };
            },
            () => {
                if (this.state.navbarOpen) {
                    document.body.style.overflow = 'hidden';
                } else {
                    document.body.style.overflow = 'unset';
                }
            }
        );
    };
    render() {
        const { location } = this.props;
        return (
            <nav className="cfp-nav">
                <div className="cfp-container">
                    <AniLink paintDrip to="/" hex="#87b136" duration={0.8}>
                        <img
                            className="cpf-menu-logo"
                            src={require('../../assets/images/cfp-logo.png')}
                            alt="CFP-logo"
                        />
                    </AniLink>
                    <ul className="nav-items left">
                        {menu.map((item, i) => {
                            const { titulo, link } = item;
                            const tit = `/${item.titulo.toLowerCase()}/`;
                            const seg = i === 0 ? '0.6s' : `${0.6 + i * 0.1}s`;
                            return (
                                <li key={titulo} style={{ animationDelay: seg }}>
                                    <AniLink
                                        paintDrip
                                        hex="#87b136"
                                        duration={0.8}
                                        to={link}
                                        className={`${location.pathname.indexOf(tit) >= 0 ? 'active' : ''}`}
                                        activeClassName="active"
                                    >
                                        {titulo}
                                    </AniLink>
                                </li>
                            );
                        })}
                    </ul>
                    <BurgerMenu navbarState={this.state.navbarOpen} toggleMenu={this.toggleMenu} />
                </div>
                {this.state.navbarOpen && (
                    <NavBarMobile
                        toggleMenu={this.toggleMenu}
                        isActive={this.state.navbarOpen}
                        location={location}
                        menu={menu}
                    />
                )}
            </nav>
        );
    }
}

export default NavBar;
