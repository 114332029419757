import React from 'react';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';

export const Phone = ({ telefono }) => {
    if (!telefono) return null;
    return (
        <div className="contact-info-row text">
            {telefono && (
                <a href={`tel:${telefono.replace(/[- )(]/g, '')}`}>
                    <span className="contact-icon">
                        <LocalPhoneIcon />
                    </span>
                    Tel: {telefono}
                </a>
            )}
        </div>
    );
};
