import React from 'react';
import './BurgerMenu.scss';

const Burgermenu = ({ toggleMenu, navbarState }) => {
    return (
        <div onClick={() => toggleMenu()} className="burger-menu" role="navigation">
            <div className={navbarState ? 'open' : ''}>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
            </div>
        </div>
    );
};

export default Burgermenu;
